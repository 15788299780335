import React, {useState} from 'react';
const Modal = ({selfRightsLvl, fetchAgain, domain}) => {
    const [modal, setModal] = useState(false);
    const [errModal, setErrModal] = useState(false);
    const [number, setNumber] = useState("");
    const modalSet = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }

    const errMod = () => {
      setErrModal(!errModal);
    }
    const numberChange = (event) => {
      setNumber(event.target.value)
    };
    function check_decoder_id(decoder_id) {
      const nn = decoder_id.split('-');
      if(nn.length != 2) return false;
      const id_crc = (nn[1] >>> 0) & 0xff;
      const id = (nn[0]<< 24) | (nn[1] >>> 8);
      let crc = 0;
        for(let i = 0; i < 4; i++) {
            crc ^= (id >>> (i*8)) & 0xff;
            for(let j = 0; j < 8; j++) {
                if((crc & 0x80) != 0) crc = ((crc << 1) ^ 7);
                else crc <<= 1;
            }
        }
      crc &= 0xff;
      return crc === id_crc;
    }
    const addNewDecoders = async() => {
      const validDecs = number.split(",").filter(dec => check_decoder_id(dec));
      const requestBody = { decoders: validDecs }
      const requestParams = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      
      try{
        if(validDecs.length == 0){
          errMod();
          throw new Error();
        }
        const responce = await fetch(`https://${domain}/api/decoders`, requestParams);
        modalSet();
        if(!responce.ok) throw new Error();
        fetchAgain();
      }catch(e){
        console.error(e);
      }
    }

    return(
      <div>
      <div className='center-box' style={selfRightsLvl < 32 ? {display: 'none'} : {}}><button className='requestBtn' onClick={modalSet}>Add</button></div>
      {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>Decoder numbers</label>
                  <input className='inputs' type='text' onChange={numberChange} />
                </div>
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={addNewDecoders}>Add</button>
                    <button className='closeBtn' onClick={modalSet}>Cancel</button>
                </div>
            </div>
        </div>}
        {errModal &&
        <div className='modal-window modal-over-modal'>
            <div className='modal-box'>
                <h1 className='msg-modal-msg'>Wrong decoder!</h1>
                <button className='requestBtn msg-modal-btn' onClick={errMod}>Ok</button>
            </div>
       </div>
        }
      
      </div>
    )
}


export default Modal