const Modal = ({msg, fetchPut, modal, modalSet, resetForDefault}) => {
    return(
      <div>
      <div className='center-box'>
        <button className='requestBtn' onClick={fetchPut}>Send</button> 
        <button className='closeBtn' onClick={resetForDefault}>Cancel</button>
      </div>
      {modal && <div className='modal-window'>
            <div className='modal-box'>
              <h1 className='msg-modal-msg'>{msg ? "Success!" : "Error"}</h1>
                
                <button className='requestBtn msg-modal-btn' onClick={modalSet} >Ok</button>
            </div>
        </div>}
      </div>
    )
}


export default Modal