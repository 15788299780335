import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate} from 'react-router-dom';


import Loading from './components/Loading';
import Login from './Login/Login';
import Subscribers from './Subscribers/Subscribers';
// import Payments from './Payments/Payments';
// import Regions from './Regions/Regions';
import Packages from './Packages/Table'
import Decoders from './Decoders/Dec'
import Pass from './PasswordChange/PassChange';
import Reports from './Reports/Reports';
import Currencies from './Currency/Table';
import Constructor from './Constructor/Constructor';
import Operators from './Operators/Operators';
import Messages from './Messages/Message';
import Error from './Error';

import { fetchSubscribersData } from './service/subscribers/comp';
import { fetchPackagesData } from './service/packages/comp';
import { fetchDecodersData } from './service/decoders/comp';
import { fetchCurrencies } from './service/currencies/comp';
import { fetchOpsData } from './service/operators/comp';
import { fetchPacksData } from './service/constructor/compCons';
import { fetchMuxData } from './service/constructor/compMux';
// import { fetchRegions } from './service/regions/comp';
const Routers = ({rightsLvl, setRightsLvl, domain}) => {
    const location = useLocation();
    const nav = useNavigate();
    //subscribers
    const [subscribers, setSubscribers] = useState({});
    //packages
    const [packages, setPackages] = useState({});
    //decoders
    const [decoders, setDecoders] = useState({});

    const [currencies, setCurrencies] = useState({});

    const [ops, setOps] = useState([]);

    const [packs, setPacks] = useState([]);
    const [checked, setChecked] = useState([[], []]);
    const [muxes, setMuxes] = useState([]);    
    const [regions, setRegions] = useState([]);

    const fetchList = async () => {
        try {
            const responce = await fetch(`https://${domain}/api/operator/profile`)
            if (!responce.ok){
                throw new Error();
            }
            const data = await responce.json();
            setRightsLvl(data.rights);
        }catch(e) {
            console.error('Произошла ошибка:', e.message);
            nav('/login');
        }
    }    


    useEffect(() => {
        if(location.pathname !== '/loading' && location.pathname !== '/login') {
            fetchList();
        }
    }, [location]);
    useEffect(() => {
        if (location.pathname !== '/login'){
            nav('/loading');
            fetchList();
            fetchOpsData(setOps, domain);
            fetchMuxData(setMuxes, domain);
            fetchCurrencies(domain, setCurrencies);
            fetchPacksData(setChecked, setPacks, domain);
            fetchSubscribersData("", "", "", 0, setSubscribers, domain);
            fetchPackagesData("", 0, setPackages, domain);
            fetchDecodersData(null, 0, setDecoders, domain);
            // fetchRegions(setRegions, domain);
            setTimeout(() => {nav('/subscribers')}, 4000);
            // try{
                
            // }catch(e){
            //     // nav('/login');
            // }
        }
    }, []);


    return(
        <Routes>
            <Route path="/" element={<Navigate to='/login' />} />
            <Route path="loading" element={<Loading />} />
            <Route path="login" element={
                <Login 
                domain={domain}
                setSubscribers={setSubscribers} 
                setPackages={setPackages} 
                setDecoders={setDecoders} 
                setRightsLvl={setRightsLvl} 
                setOps={setOps}
                setMuxes={setMuxes}
                setCurrencies={setCurrencies}
                setChecked={setChecked}
                setPacks={setPacks}
                // setPayments={setPayments}
            />}/>
            <Route path="subscribers" element={<Subscribers domain={domain} dataL={subscribers} setDataL={setSubscribers} selfRightsLvl={rightsLvl} currenciesL={currencies}/>}/>
            <Route path="packages" element={<Packages domain={domain} selfRightsLvl={rightsLvl} dataL={packages} setDataL={setPackages}/>}/>
            {/* <Route path="regions" element={<Regions domain={domain} selfRightsLvl={rightsLvl} dataL={regions} setDataL={setRegions}/>}/> */}
            <Route path="currency" element={<Currencies domain={domain} selfRightsLvl={rightsLvl} dataL={currencies} setDataL={setCurrencies}/>}/>
            <Route path="messages" element={<Messages domain={domain}/>}/>
            
            {/* <Route path="payments" element={<Payments domain={domain} selfRightsLvl={rightsLvl} dataL={payments} setDataL={setPayments}/>} /> */}
            <Route path="decoders" element={<Decoders domain={domain} selfRightsLvl={rightsLvl} dataL={decoders} setDataL={setDecoders}/>}  />
            <Route path="password" element={<Pass domain={domain}/>}/>
            <Route path="reports" element={<Reports domain={domain}/>}/>
            <Route path="constructor" element={<Constructor domain={domain} selfRightsLvl={rightsLvl} checkedL={checked} setCheckedL={setChecked} packsL={packs} muxes={muxes} setMuxes={setMuxes}/>}/>
            <Route path="operators" element={<Operators domain={domain} selfRightsLvl={rightsLvl} dataL={ops} setDataL={setOps}/>}/>
            <Route path='*' element={<Error/>}/>
        </Routes>
    )
}

export default Routers;