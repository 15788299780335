import React, { useEffect, useState } from 'react';
import Routers from './app/Router';
import { BrowserRouter } from 'react-router-dom';
import Header from './app/Header';
import './App.css'
import Cookies from "js-cookie";
function App() {
  const domain = new URL(window.location.href).hostname; 
  const [rightsLvl, setRightsLvl] = useState(0);
  const setter = (param) => {
    setRightsLvl(param);
  }
  // useEffect(() => {
  //   // Проверяем, был ли сайт уже открыт в этой сессии
  //   if (!sessionStorage.getItem("visited")) {
  //     Cookies.set("username", "JohnDoe", { path: "/" }); // Устанавливаем cookie
  //     sessionStorage.setItem("visited", "true"); // Запоминаем, что сессия активирована
  //   }

  //   const clearCookies = () => {
  //     const allCookies = Cookies.get();
  //     Object.keys(allCookies).forEach((cookieName) => {
  //       Cookies.remove(cookieName, { path: "/" });
  //     });
  //   };

  //   window.addEventListener("beforeunload", clearCookies);

  //   return () => {
  //     window.removeEventListener("beforeunload", clearCookies);
  //   };
  // }, []);

  useEffect(() => {
    const handleLogout = () => {
        const logoutUrl = `https://${domain}/api/operator/logout`;
        const data = JSON.stringify({});

        // Отправляем logout-запрос
        navigator.sendBeacon(logoutUrl, data);
    };

    window.addEventListener("beforeunload", handleLogout);

    return () => {
        window.removeEventListener("beforeunload", handleLogout);
    };
  }, []);

  return (
    // <Test/>
      <BrowserRouter> 
        <div className="App">
          <Header rights={rightsLvl} domain={domain}/>
          <Routers rightsLvl={rightsLvl} setRightsLvl={setter} domain={domain}/>
        </div>
      </BrowserRouter>
  );
}

export default App;