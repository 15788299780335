import PutMsg from './PutMsg';
import DltMsg from './DltMsg';
import './index.css'
const Messages = ({domain}) => {
 
    return(
      <div className="packages box"> 
        <PutMsg domain={domain} />
        <DltMsg domain={domain} />
      </div>
    )
}
export default Messages;