import AddMod from './Modal';
import React, {useState, useEffect} from 'react';

const PutMsg = ({domain}) => {
    // const [decoders, setDecoders] = useState();
    const [decId, setId] = useState("broadcast");
    const [dispTime, setDispTime] = useState(0);
    const [ttl, setTtl] = useState(36000);
    const [lang, setLang] = useState("en");
    const [message, setMessage] = useState(null);
    const [msg, setmsg] = useState(true)    ;
    const handleId = (e) => {
        setId(e.target.value)
    }
    const handleDisp = (e) => {
        try{
            const r = parseInt(e.target.value !== "" ? e.target.value : 0);
            if(r == NaN) throw Error();
            setDispTime(r);
        }catch(e){
            setDispTime(0);
        }
    }
    const handleTtl = (e) => {
        try{
            const r = parseInt(e.target.value !== "" ? e.target.value : 0);
            if(r == NaN) throw Error();
            setTtl(r);
        }catch(e){
            setTtl(36000);
        }
    }
    const handleLang = (e) => {
        setLang(e.target.value)
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }

    const handleCheckbox = (e) => {
        setId(e.target.checked ? "broadcast" : ""); 
    }

    const [modal, setModal] = useState(false);
    const modalSet = () => {
        setModal(!modal);
        document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }


    const resetForDefault = () => {
        setId("broadcast");
        setDispTime(0);
        setTtl(36000);
        setLang("en");
        setMessage("");
    }

    useEffect(() => {
        if(message === "") setMessage(null);
    }, [message])

    const fetchPut = async () => {
        const reqBody = {
            decoder_id: decId, 
            display_time: dispTime,      
            ttl: ttl,             
            lang: lang,             
            message: message  
        }
        const requestParams = {
            method: "PUT",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        }
        try{
            const resp = await fetch(`https://${domain}/api/sms`, requestParams);
            if (!resp.ok) throw Error();
            setmsg(true);
        }catch(e){
            setmsg(false);
        }finally{
            resetForDefault();
            modalSet();
        }
    }


    return <div className="msg-inputs">
        <div className='input-areas'>
            <label>Decoder ID</label>
            <input className='inputs' type='text' value={decId !== "broadcast" ? decId : ""} onChange={handleId}/>
            <input type="checkbox" checked={decId === "broadcast"} name="" id="" onChange={handleCheckbox} /> All decoders
        </div>
        <div className='input-areas'>
            <label>Display time (in seconds)</label>
            <input className='inputs' type='text' value={dispTime} onChange={handleDisp}/>
        </div>
        <div className='input-areas'>
            <label>Time to live(in seconds)</label>
            <input className='inputs' type='text' value={ttl} onChange={handleTtl}/>
        </div>
        <div className='input-areas'>
            <label>Message language</label>
            <input className='inputs' type='text' value={lang} onChange={handleLang}/>
        </div>
        <div className='input-areas'>
            <label>Message text</label>
            <input className='inputs' type='text' value={message} onChange={handleMessage}/>
        </div>
        <AddMod msg={msg} fetchPut={fetchPut} modal={modal} modalSet={modalSet} resetForDefault={resetForDefault}/>
    </div>
}

export default PutMsg