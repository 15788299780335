import AddMod from './DeleteModal';
import React, {useState, useEffect} from 'react';

const PutMsg = ({domain}) => {
    // const [decoders, setDecoders] = useState();
    const [decId, setId] = useState("broadcast");
    const [msg, setmsg] = useState(true);
    const handleId = (e) => {
        setId(e.target.value)
    }
    const handleCheckbox = (e) => {
        setId(e.target.checked ? "broadcast" : ""); 
    }

    const [modal, setModal] = useState(false);
    const modalSet = () => {
        setModal(!modal);
        document.body.style.overflow = !modal ? 'hidden' : 'auto';
      }

    const fetchDelt = async () => {
        const requestParams = {
            method: "DELETE",
            // headers: {'Content-Type': 'application//json'},
            // body: JSON.stringify(reqBody)
        }
        try{
            const resp = await fetch(`https://${domain}/api/sms/${decId === "" ? "broadcast" : decId}`, requestParams);
            if (!resp.ok) throw Error();
            setmsg(true);
        }catch(e){
            setmsg(false);
        }finally{
            setId("broadcast");
            modalSet();
        }
    }

    return <div className="msg-inputs">
        <div className='input-areas'>
            <label>Delete message for Decoder ID</label>
            <input className='inputs' type='text' value={decId !== "broadcast" ? decId : ""} onChange={handleId}/>
            <input type="checkbox" checked={decId === "broadcast"} name="" id="" onChange={handleCheckbox} /> All decoders
        </div>
        <AddMod msg={msg} fetchDelt={fetchDelt} modal={modal} modalSet={modalSet}/>
    </div>
}

export default PutMsg